<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    ref="iframeWrapper"
    v-editable="blok"
    class="w-[calc(100%+2.5rem)] -translate-x-5 transform lg:w-auto lg:transform-none"
    v-html="iframeCode"
  />
  <!-- eslint-enable -->
</template>

<script setup lang="ts">
import type { BlockEnquiryFormStoryblok } from '@/types/storyblok'

const props = defineProps<{
  blok: BlockEnquiryFormStoryblok
}>()

const iframeWrapper = ref<HTMLDivElement | null>(null)

const setMappings = computed(() => {
  return typeof props.blok.mappingSet === 'string'
    ? undefined
    : props.blok.mappingSet?.content.mappings
})
const { resolvedMappingSet } = useStoryblokDataMappings(setMappings)

const iframeCode = computed(() => {
  if (!resolvedMappingSet.value) return props.blok.jotformIframeCode

  // Append prefill data to src as query string
  const queryString = new URLSearchParams(resolvedMappingSet.value).toString()
  return props.blok.jotformIframeCode.replace(
    /src="([^"]*)"/,
    (match, p1) => `src="${p1}?${queryString}"`,
  )
})

onMounted(() => {
  removeJotformOnload()
})

const removeJotformOnload = () => {
  // The Jotform iframe code has an onload event that causes the page to scroll to the top
  // This removes the onload event to prevent the iframe overriding the default Nuxt scroll behavior
  const iframe = iframeWrapper.value?.getElementsByTagName('iframe')[0]
  if (iframe && iframe.src.includes('jotform') && iframe.onload) {
    iframe.removeAttribute('onload')
  }
}
</script>
