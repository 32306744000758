import type { DataFormFieldMappingStoryblok } from '@/types/storyblok'

export type StoryblokMappingDataField =
  | 'communityName'
  | 'propertyName'
  | 'propertyId'
  | 'propertyAddress'

export default function (
  mappingSet: MaybeRefOrGetter<DataFormFieldMappingStoryblok[] | undefined>,
) {
  const { story } = storeToRefs(useCurrentStoryStore())

  const resolvedMappingSet = computed(() => {
    const resolvedMappingSet: Record<string, string> = {}

    // If "Storyblok Data" value type selected, we need to use resolveStoryblokDataMapping() to get the relevant story data.
    // Otherwise, we just grab the data from the matching field
    toValue(mappingSet)?.forEach((mapping: DataFormFieldMappingStoryblok) => {
      const mappedData =
        mapping.valueType === 'storyblokDataMapping'
          ? resolveStoryblokDataMapping(
              mapping.storyblokDataMapping as StoryblokMappingDataField,
            )
          : mapping[mapping.valueType] + ''

      if (mappedData) {
        resolvedMappingSet[mapping.formFieldName] = mappedData
      }
    })

    return resolvedMappingSet
  })

  // Resolve storyblok data within the context of the current page
  const resolveStoryblokDataMapping = (
    storyblokDataMapping: StoryblokMappingDataField,
  ) => {
    if (isContentTypeCommunityStoryblok(story.value)) {
      if (storyblokDataMapping === 'communityName') return story.value.name
    }

    if (isContentTypePropertyStoryblok(story.value)) {
      if (storyblokDataMapping === 'propertyName') {
        return story.value.name
      }
      if (storyblokDataMapping === 'communityName') {
        const { communityName } = storeToRefs(useCurrentStoryStore())

        return communityName.value
      }

      const { propertyStoryblokDataMappings } = usePropertyData(
        story.value.content,
      )

      if (storyblokDataMapping === 'propertyAddress') {
        return propertyStoryblokDataMappings.value.address
      }
      if (storyblokDataMapping === 'propertyId') {
        return propertyStoryblokDataMappings.value.id
      }
    }

    return undefined
  }

  return { resolvedMappingSet }
}
